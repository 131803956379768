///mobile nav work

const btnNavEl = document.querySelector(".btn-mobile-nav");
const headerEl = document.querySelector("header");
const navLinks = document.querySelectorAll(".main-nav-link");
const toggleNav = function () {
  headerEl.classList.toggle("nav-open");
};

btnNavEl.addEventListener("click", function () {
  toggleNav();
});

navLinks.forEach((navLink) => navLink.addEventListener("click", toggleNav));

// //reveal sections
// const allSections = document.querySelectorAll(".section");
// const revealSection = function (entries, observer) {
//   const [entry] = entries;
//   console.log(entry);
//   if (!entry.isIntersecting) {
//     return;
//   }
//   entry.target.classList.remove("section--hidden");
//   //stop observing the section, for performance
//   observer.unobserve(entry.target);
// };

// const sectionObserver = new IntersectionObserver(revealSection, {
//   root: null,
//   threshold: 0.15, //15%
// });
// allSections.forEach(function (section) {
//   sectionObserver.observe(section);
//   section.classList.add("section--hidden");
// });

//
const elRight = document.querySelectorAll(".transform--right");
const elLeft = document.querySelectorAll(".transform--left");
const preloader = document.querySelector(".preloader");
const animate = function () {
  elRight.forEach(function (element) {
    element.classList.remove("section--hidden--right");
  });
  elLeft.forEach(function (element) {
    element.classList.remove("section--hidden--left");
  });
};
const hidePreloader = function () {
  preloader.style.display = "none";
};
const handleLoad = function () {
  hidePreloader();
  animate();
};
// elRight.forEach(function (element) {
//   element.classList.add(``);
// });
// elLeft.forEach(function (element) {
//   element.classList.add(`section--hidden--left`);
// });
window.addEventListener("load", handleLoad);

// //reveal sections right
// const allSectionsRight = document.querySelectorAll(".transform--right");
// const revealSectionRight = function (entries, observer) {
//   const [entry] = entries;
//   console.log(entry);
//   if (!entry.isIntersecting) {
//     return;
//   }
//   entry.target.classList.remove("section--hidden--right");
//   //stop observing the section, for performance
//   observer.unobserve(entry.target);
// };

// const sectionObserverRight = new IntersectionObserver(revealSectionRight, {
//   root: null,
//   threshold: 0.15, //15%
// });
// allSectionsRight.forEach(function (section) {
//   sectionObserverRight.observe(section);
//   section.classList.add("section--hidden--right");
// });

// //reveal sections left
// const allSectionsLeft = document.querySelectorAll(".transform--left");
// const revealSectionLeft = function (entries, observer) {
//   const [entry] = entries;
//   console.log(entry);
//   if (!entry.isIntersecting) {
//     return;
//   }
//   entry.target.classList.remove("section--hidden--left");
//   //stop observing the section, for performance
//   observer.unobserve(entry.target);
// };

// const sectionObserverLeft = new IntersectionObserver(revealSectionLeft, {
//   root: null,
//   threshold: 0.15, //15%
// });
// allSectionsLeft.forEach(function (section) {
//   sectionObserverLeft.observe(section);
//   section.classList.add("section--hidden--left");
// });

//cta-form
document
  .getElementById("cta-button")
  .addEventListener("click", function (event) {
    event.preventDefault();
    const name = document.getElementById("name").value;
    const contactInfo = document.getElementById("contactInfo").value;
    const comment = document.getElementById("comment").value;
    if (!name || !contactInfo) {
      return;
    }
    function reqListener() {
      console.log(this.responseText);
      if (this.status === 200) {
        document.querySelector(".notification-box").style.display = "block";
      }
    }

    var oReq = new XMLHttpRequest();
    oReq.addEventListener("load", reqListener);
    oReq.open(
      "GET",
      `https://api.telegram.org/bot5548513336:AAFNYD78KZPYSdI-RqQcuHqFYazepmwKJJQ/sendMessage?chat_id=@YMScontact&text=${name}---${contactInfo}---${comment}`
    );
    oReq.send();
  });
document
  .getElementById("notification-button")
  .addEventListener("click", function (event) {
    event.preventDefault();
    document.querySelector(".notification-box").style.display = "none";
  });

//Sticky navigation
const setionHeroEl = document.querySelector(".hero");
const obs = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];
    if (!ent.isIntersecting) {
      console.log(ent);
      document.body.classList.add("sticky");
    }
    if (ent.isIntersecting) {
      document.body.classList.remove("sticky");
    }
  },
  {
    root: null,
    threshold: 0,
    rootMargin: "-80px",
  }
);
obs.observe(setionHeroEl);

//projects height fix
// const slider1 = document.querySelector(".slide--1");
// const setHeight = function () {
//   const slideStyle = getComputedStyle(slider1);
//   document.querySelector(".slider").style.height = slideStyle.height;
// };
// // const config = {
// //   attributes: true,
// //   childList: true,
// //   characterData: true,
// //   subtree: true,
// // };

// // var observer = new MutationObserver(setHeight);

// // observer.observe(slider1, config);

// document.addEventListener("DOMContentLoaded", setHeight);

///////////////////////////////////////////////////////////
// Fixing flexbox gap property missing in some Safari versions
function checkFlexGap() {
  var flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);
  console.log(isSupported);

  if (!isSupported) document.body.classList.add("no-flexbox-gap");
}
checkFlexGap();

///////
// Projects
///////
// const projectBtn = document.querySelector(".project-btn");
// const projectImg = document.querySelector(".project-img-box");

// projectBtn.addEventListener("click", function () {
//   projectImg.style.transition = "unset";
//   projectImg.style.backgroundImage =
//     "url(/yms/img/sections/projects/omnifood_phone.jpg)";
// });
